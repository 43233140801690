import React, { useState } from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import { getDigitsAfterDecimal } from "../../../components/helper";

const spotNavigation = ["My Open Orders", "Market Trades",];
const crossNavigation = ["My Open Orders", "Market Trades", "Positions"];

const Table = ({
  marketTrades,
  openOrders,
  orderHandler,
  amountDecimal,
  priceDecimal,
  tradingType,
  openPosition,
  openPositionCross,
  activeTab,
  setConfirmationModal,
  setItem,
  closeButtonStatus
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.inner}>
      <div className={styles.nav}>
        {
          tradingType?.toLowerCase() === "spot" ?
            spotNavigation?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))
            :
            crossNavigation?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {(activeTab?.toLowerCase() === "cross" && index === 2 && openPositionCross[0]?.count > 0) ? `${x} (${openPositionCross[0]?.count})` :
                  activeTab?.toLowerCase() === "isolated" && index === 2 && openPosition[0]?.count > 0 ? `${x} (${openPosition[0]?.count})` : x}
              </button>
            ))
        }
      </div>
      <div className={styles.table}>
        {
          (tradingType?.toLowerCase() === "cross" && activeIndex === 2) ?
            <div className={styles.row}>
              <div className={styles.col}>
                Coin
              </div>
              <div className={styles.col}>
                Position(QTY/USDT)
              </div>
              <div className={styles.col}>
                Index Price(USDT)
              </div>
              <div className={styles.col}>
                Entry Price(USDT)
              </div>
              <div className={styles.col}>
                PNL(USDT)
              </div>
              <div className={styles.col}>
                Est. Liq. Price(USDT)
              </div>
              <div className={styles.col}>
                Debt(QTY/USDT)
              </div>
              <div className={styles.col}>
                Action
              </div>
            </div>
            :
            <div className={styles.row}>
              <div className={styles.col}>
                <div className="">Last updated</div>
              </div>
              <div className={styles.col}>
                <div className="">Type</div>
              </div>
              <div className={styles.col}>
                <div className="">Order ID</div>
              </div>
              <div className={styles.col}>
                <div className="">Market</div>
              </div>
              <div className={styles.col}>
                <div className="">Price</div>
              </div>
              <div className={styles.col}>
                <div className="">Amount</div>
              </div>
              <div className={styles.col}>
                <div className="">Total</div>
              </div>
              {activeIndex === 0 &&
                (<div className={styles.col}>
                  <div className="">Fee</div>
                </div>
                )}
              {activeIndex == 1 ?
                <div className={styles.col}>
                  <div className="">Status</div>
                </div> :
                null
              }
              {activeIndex == 0 ?
                <div className={styles.col}>
                  <div >Action </div>
                </div> :
                null
              }
            </div>
        }
        {/* <div className={styles.row}></div> */}
        {
          (tradingType?.toLowerCase() === "cross" && activeIndex === 2) ?
            activeTab?.toLowerCase() === "cross" ?
              openPositionCross?.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {
                      x?.side === "buy" ?
                        x.currency?.toUpperCase() :
                        x?.coin?.toUpperCase()
                    }
                  </div>
                  <div className={styles.col}>
                    {parseFloat(x?.profit) > 0 ?
                      <div className={styles.positive}>
                        {getDigitsAfterDecimal(x.qty_crypto, x?.amountDecimalPrecision)}
                        <div>
                          {getDigitsAfterDecimal(x?.qty_fiat, x?.priceDecimalPrecision)}
                        </div>
                      </div>
                      :
                      <div className={styles.negative}>
                        {getDigitsAfterDecimal(x.qty_crypto, x?.amountDecimalPrecision)}
                        <div>
                          {getDigitsAfterDecimal(x?.qty_fiat, x?.priceDecimalPrecision)}
                        </div>
                      </div>
                    }
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.current_market_price, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.entry_price, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    {
                      parseFloat(x?.profit) > 0 ?
                        <div className={styles.positive}>
                          {getDigitsAfterDecimal(x?.profit, 8)}
                        </div>
                        :
                        <div className={styles.negative}>
                          {`-${getDigitsAfterDecimal(x?.loss, 8)}`}
                        </div>
                    }
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.liquidationPrice, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.borrowed_amount, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    {
                      <button
                        className={cn("button-small button")}
                        disabled={closeButtonStatus}
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmationModal(true);
                          setItem(x);
                        }}
                      >
                        Close Position
                      </button>
                    }
                  </div>
                </div>
              ))
              :
              openPosition?.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {
                      x?.side === "buy" ?
                        x.currency?.toUpperCase() :
                        x?.coin?.toUpperCase()
                    }                  </div>
                  <div className={styles.col}>
                    {parseFloat(x?.profit) > 0 ?
                      <div className={styles.positive}>
                        {getDigitsAfterDecimal(x.qty_crypto, x?.amountDecimalPrecision)}
                        <div>
                          {getDigitsAfterDecimal(x?.qty_fiat, x?.priceDecimalPrecision)}
                        </div>
                      </div>
                      :
                      <div className={styles.negative}>
                        {getDigitsAfterDecimal(x.qty_crypto, x?.amountDecimalPrecision)}
                        <div>
                          {getDigitsAfterDecimal(x?.qty_fiat, x?.priceDecimalPrecision)}
                        </div>
                      </div>
                    }
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.current_market_price, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.entry_price, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    {
                      parseFloat(x?.profit) > 0 ?
                        <div className={styles.positive}>
                          {getDigitsAfterDecimal(x?.profit, x?.priceDecimalPrecision)}
                        </div>
                        :
                        <div className={styles.negative}>
                          {getDigitsAfterDecimal(x?.loss, x?.priceDecimalPrecision)}
                        </div>
                    }
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.liquidationPrice, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    {getDigitsAfterDecimal(x?.borrowed_amount, x?.priceDecimalPrecision)}
                  </div>
                  <div className={styles.col}>
                    <button
                      className={cn("button-small button")}
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationModal(true);
                        setItem(x);
                      }}
                    >
                      Close Position
                    </button>
                  </div>
                </div>
              ))
            :
            activeIndex == 0 ?
              tradingType?.toLowerCase() === "spot" ?
                openOrders?.slice(0, 7).filter((y) => y?.trade_type !== null && y?.trade_type === parseInt(process.env.REACT_APP_TRADE_TYPE_SPOT)).map((x, index) =>
                (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      {new Date(x.updatedAt).toLocaleString().slice(0, 10)}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>Buy</div>
                        )
                        :
                        (
                          <div className={styles.negative}>Sell</div>
                        )
                      }
                    </div>
                    <div className={styles.col}>
                      {x.orderId}
                    </div>
                    <div className={styles.col}>
                      {x.slug.toUpperCase()}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>
                            {getDigitsAfterDecimal(x.price, x.priceDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </div>
                        ) :
                        (
                          <div className={styles.negative}>
                            {getDigitsAfterDecimal(x.price, x.priceDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </div>
                        )}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType === process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>
                            {getDigitsAfterDecimal(x.crypto_amount, x.amountDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span> {x.crypto_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈ {getDigitsAfterDecimal(x.crypto_exe_amount, x.amountDecimalPrecision)})</div>)}
                          </div>
                        ) : (
                          <div className={styles.negative}>
                            {getDigitsAfterDecimal(x.crypto_amount, x.amountDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span> {x.crypto_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈ {getDigitsAfterDecimal(x.crypto_exe_amount, x.amountDecimalPrecision)})</div>)}
                          </div>
                        )}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>
                            {getDigitsAfterDecimal(x.fiat_amount, x.priceDecimalPrecision)}<span className={styles.conv}>{x?.slug.split("-")[1].toUpperCase()}</span> {x.fiat_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈  {getDigitsAfterDecimal(x.fiat_exe_amount, x.priceDecimalPrecision)})</div>)}
                          </div>
                        ) : (
                          <div className={styles.negative}>
                            {getDigitsAfterDecimal(x.fiat_amount, x.priceDecimalPrecision)}<span className={styles.conv}>{x?.slug.split("-")[1].toUpperCase()}</span> {x.fiat_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈  {getDigitsAfterDecimal(x.fiat_exe_amount, x.priceDecimalPrecision)})</div>)}
                          </div>
                        )}
                    </div>
                    {/* <div className={styles.col}>{getDigitsAfterDecimal(x?.fiatFee, x?.priceDecimalPrecision)} <span className={styles.conv}>{x?.slug.split("-")[1].toUpperCase()}</span></div> */}
                    {activeIndex === 0 && (
                      <div className={styles.col}>
                        {(x?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || x?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) ?
                          <>
                            {getDigitsAfterDecimal(x?.fiatFee, x?.priceDecimalPrecision, true)} <span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </>
                          :
                          (x?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || x?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) ?
                            <>
                              {getDigitsAfterDecimal(x?.cryptoFee, x?.amountDecimalPrecision)} <span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span>
                            </>
                            :
                            null}
                      </div>
                    )}
                    <div className={styles.col}>
                      <button
                        className={cn("button-small button-red", styles.button)}
                        onClick={(e) => {
                          e.preventDefault();
                          orderHandler(x);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ))
                :
                openOrders?.slice(0, 7).filter((y) => y?.trade_type !== null && y?.trade_type !== parseInt(process.env.REACT_APP_TRADE_TYPE_SPOT)).map((x, index) =>
                (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      {new Date(x.updatedAt).toLocaleString().slice(0, 10)}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>Buy</div>
                        )
                        :
                        (
                          <div className={styles.negative}>Sell</div>
                        )
                      }
                    </div>
                    <div className={styles.col}>
                      {x.orderId}
                    </div>
                    <div className={styles.col}>
                      {x.slug.toUpperCase()}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>
                            {getDigitsAfterDecimal(x.price, x.priceDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </div>
                        ) :
                        (
                          <div className={styles.negative}>
                            {getDigitsAfterDecimal(x.price, x.priceDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </div>
                        )}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType === process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>
                            {getDigitsAfterDecimal(x.crypto_amount, x.amountDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span> {x.crypto_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈ {getDigitsAfterDecimal(x.crypto_exe_amount, x.amountDecimalPrecision)})</div>)}
                          </div>
                        ) : (
                          <div className={styles.negative}>
                            {getDigitsAfterDecimal(x.crypto_amount, x.amountDecimalPrecision)}<span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span> {x.crypto_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈ {getDigitsAfterDecimal(x.crypto_exe_amount, x.amountDecimalPrecision)})</div>)}
                          </div>
                        )}
                    </div>
                    <div className={styles.col}>
                      {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER || x.orderType == process.env.REACT_APP_BUY_MARKET_ORDER ?
                        (
                          <div className={styles.positive}>
                            {getDigitsAfterDecimal(x.fiat_amount, x.priceDecimalPrecision)}<span className={styles.conv}>{x?.slug.split("-")[1].toUpperCase()}</span> {x.fiat_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈  {getDigitsAfterDecimal(x.fiat_exe_amount, x.priceDecimalPrecision)})</div>)}
                          </div>
                        ) : (
                          <div className={styles.negative}>
                            {getDigitsAfterDecimal(x.fiat_amount, x.priceDecimalPrecision)}<span className={styles.conv}>{x?.slug.split("-")[1].toUpperCase()}</span> {x.fiat_exe_amount > 0 && (<div className={styles.conv}> (P.filled ≈  {getDigitsAfterDecimal(x.fiat_exe_amount, x.priceDecimalPrecision)})</div>)}
                          </div>
                        )}
                    </div>
                    {/* <div className={styles.col}>{getDigitsAfterDecimal(x?.fiatFee, x?.priceDecimalPrecision)} <span className={styles.conv}>{x?.slug.split("-")[1].toUpperCase()}</span></div> */}
                    {activeIndex === 0 && (
                      <div className={styles.col}>
                        {(x?.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || x?.orderType === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER)) ?
                          <>
                            {getDigitsAfterDecimal(x?.fiatFee, x?.priceDecimalPrecision, true)} <span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </>
                          :
                          (x?.orderType === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || x?.orderType === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER)) ?
                            <>
                              {getDigitsAfterDecimal(x?.cryptoFee, x?.amountDecimalPrecision)} <span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span>
                            </>
                            :
                            null}
                      </div>
                    )}
                    <div className={styles.col}>
                      {parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER) || parseInt(x?.orderType) === parseInt(process.env.REACT_APP_BUY_MARKET_ORDER) ?
                        parseFloat(x?.fiat_exe_amount) > 0 ?
                          "N/A" :
                          <button
                            className={cn("button-small button-red", styles.button)}
                            onClick={(e) => {
                              e.preventDefault();
                              orderHandler(x);
                            }}
                          >
                            Cancel
                          </button>
                        :
                        parseInt(x?.orderType) === parseInt(process.env.REACT_APP_SELL_LIMIT_ORDER) || parseInt(x?.orderType) === parseInt(process.env.REACT_APP_SELL_MARKET_ORDER) ?
                          parseFloat(x?.crypto_exe_amount) > 0 ?
                            "N/A" :
                            <button
                              className={cn("button-small button-red", styles.button)}
                              onClick={(e) => {
                                e.preventDefault();
                                orderHandler(x);
                              }}
                            >
                              Cancel
                            </button> :
                          ""
                      }
                    </div>
                  </div>
                ))
              :
              marketTrades.slice(0, 7).map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {new Date(x.updatedAt).toLocaleString().slice(0, 10)}
                  </div>
                  <div className={styles.col}>
                    {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER ?
                      (
                        <div className={styles.positive}>Buy</div>
                      ) :
                      (
                        <div className={styles.negative}>Sell</div>
                      )
                    }
                  </div>
                  <div className={styles.col}>
                    {x.orderId}
                  </div>
                  <div className={styles.col}>
                    {x.slug.toUpperCase()}
                  </div>
                  <div className={styles.col}>
                    {
                      x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER ?
                        (
                          <div className={styles.positive}>{getDigitsAfterDecimal(x.price, priceDecimal)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </div>
                        ) :
                        (
                          <div className={styles.negative}>{getDigitsAfterDecimal(x.price, priceDecimal)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                          </div>
                        )
                    }
                  </div>
                  <div className={styles.col}>
                    {
                      x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER ?
                        (
                          <div className={styles.positive}>
                            {getDigitsAfterDecimal(x.crypto_amount, amountDecimal)}<span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span>
                          </div>
                        ) :
                        (
                          <div className={styles.negative}>
                            {getDigitsAfterDecimal(x.crypto_amount, amountDecimal)}<span className={styles.conv}> {x?.slug.split("-")[0].toUpperCase()}</span>
                          </div>
                        )
                    }
                  </div>
                  <div className={styles.col}>
                    {x.orderType == process.env.REACT_APP_BUY_LIMIT_ORDER ?
                      (
                        <div className={styles.positive}>
                          {getDigitsAfterDecimal(x.fiat_amount, priceDecimal)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                        </div>
                      ) :
                      (
                        <div className={styles.negative}>
                          {getDigitsAfterDecimal(x.fiat_amount, priceDecimal)}<span className={styles.conv}> {x?.slug.split("-")[1].toUpperCase()}</span>
                        </div>
                      )
                    }
                  </div>
                  <div className={styles.col}>Filled</div>
                </div>
              ))}
      </div>
    </div>
  );
};

export default Table;
