import historyProvider from "./historyProvider";
import stream from "./stream";
import { store } from "../../index.js";

const supportedResolutions = [
  "1",
  "3",
  "5",
  "15",
  "30",
  "60",
  "120",
  "240",
  "360",
  "D",
  "W",
  "M",
];

const config = {
  supported_resolutions: supportedResolutions
};

export default {
  onReady: function (cb) {
    cb(config);
  },

  resolveSymbol: function (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) {
    // Expects a symbolInfo object in response
    var split_data = symbolName.split(/[:/]/);
    let marketEntry = store.getState().markets.socketMarketData[0]?.markets.find(entry => entry.slug.toLowerCase() === split_data[1].toLowerCase());
    var symbol_stub = {
      name: split_data[1],
      description: "",
      type: "crypto",
      session: "24x7",
      timezone: "Etc/UTC",
      ticker: symbolName,
      exchange: split_data[0],
      minmov: 1,
      pricescale: marketEntry ? 10 ** marketEntry?.priceDecimalPrecision : 10 ** 8,
      has_intraday: true,
      supported_resolutions: supportedResolutions,
      intraday_multipliers: ["1", "5", "15", "30", "120", "240"],
      data_status: "streaming",
    };

    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  },

  getBars: function (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onError,
    interval
  ) {
    //send curruntTime instead of from and to 
    //modify backend according to that
    const { to } = periodParams;
    const countBack = periodParams.countBack;
    const countForward = periodParams.countForward;

    historyProvider
      .getBars(
        symbolInfo,
        resolution,
        to * 1000,
        countBack,
        onHistoryCallback
      )
      .then(function (bars) {
        if (bars.length) {
          // Calculate the Moving Average (MA)
          const maPeriod = 100; // Adjust this value as needed
          const maValues = calculateMovingAverage(bars, maPeriod);

          // Add MA values to each bar
          const barsWithMA = bars.map(function (bar, index) {
            return {
              ...bar,
              ma: maValues[index],
            };
          });

          onHistoryCallback(barsWithMA, { noData: false });
        } else {
          onHistoryCallback(bars, { noData: true });
        }
      })
      .catch(function (err) {
        onError(err);
      });
  },
  subscribeBars: function (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) {
    stream.subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    );
  },

  unsubscribeBars: function (subscriberUID) {
    stream.unsubscribeBars(subscriberUID);
  },

  calculateHistoryDepth: function (
    resolution,
    resolutionBack,
    intervalBack
  ) {
    // Optional
    // While optional, this makes sure we request 24 hours of minute data at a time
    // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60
      ? { resolutionBack: "D", intervalBack: "1" }
      : undefined;
  },

  getMarks: function (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) {
    // Optional
  },

  getTimeScaleMarks: function (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) {
    // Optional
  },

  getServerTime: function (cb) { },
};

function calculateMovingAverage(bars, period) {
  const maValues = [];

  for (let i = period - 1; i < bars.length; i++) {
    const closePrices = bars
      .slice(i - period + 1, i + 1)
      .map(function (bar) { return bar.close; });
    const sum = closePrices.reduce(function (total, price) { return total + price; }, 0);
    const ma = sum / period;
    maValues.push(ma);
  }

  return maValues;
}
