import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Action.module.sass";
import Icon from "../../../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { newOrderCreator, newOrderEmpty } from "../../../../../actions/newOrder";
import { addNotification } from "../../../../../components/Notification";
import { getDigitsAfterDecimal } from "../../../../../components/helper";
import { userStatusCreator } from "../../../../../actions/getUserStatus";
import requestHandler from "../../../../../actions/httpClient";
// import { io } from "socket.io-client";
import { socket } from "../../../../../socket";

const takerFee = parseFloat(process.env.REACT_APP_TAKER_FEE);
const makerFee = parseFloat(process.env.REACT_APP_MAKER_FEE);
const spread = parseFloat(process.env.REACT_APP_ORDER_SPREAD);

const navigation = ["Borrow"];

const Action = ({
  title,
  content,
  price,
  stop,
  limit,
  classButton,
  buttonText,
  orderType,
  currentPrice,
  slug,
  marketId,
  coin,
  currency,
  buyBalance,
  sellBalance,
  orderResponse,
  tradingType,
  currentMarketDetails,
  activeTab,
  currencyAmount,
  coinAmount,
  setOrderFlag,
  orderFlag,
  exchangeData,
  createOrdersData,
  activeIndexAction,
  leverage,
  currentMarketPrice,
  crossMarginLeverage,
  isolatedMarginLeverage,
  leverageState,
  setMarketOrderType,
  crossMarginSellLeverage,
  isolatedMarginSellLeverage,
}) => {
  const dispatch = useDispatch()
  const [warning, setWarning] = useState('');
  const [sellWarning, setSellWarning] = useState('');
  const [disableButton, setDisableButon] = useState(false)
  const [sellDisableButton, setSellDisableButon] = useState(false)
  const { response, orderError, loading } = useSelector((state) => { return state.newOrder });
  const { userStatus } = useSelector((state) => { return state.getUserStatus });

  //limit price
  const [buyPriceLimit, setBuyPriceLimit] = useState('');
  const [sellPriceLimit, setSellPriceLimit] = useState('');

  //amount that user want to get
  const [amount, setAmount] = useState('');
  const [sellAmount, setSellAmount] = useState('');

  //total amount to show
  const [buyTotal, setBuyTotal] = useState('');
  const [sellTotal, setSellTotal] = useState('');
  //
  const [buyLoading, setBuyLoading] = useState(false)
  const [sellLoading, setSellLoading] = useState(false)

  const [activeIndex, setActiveIndex] = useState(0);
  const [buyBorrowing, setBuyBorrowing] = useState(0);
  const [sellBorrowing, setSellBorrowing] = useState(0);
  const [buyEstFee, setBuyEstFee] = useState(0);
  const [sellEstFee, setSellEstFee] = useState(0);
  const [buyBorrowingAmount, setBuyBorrowingAmount] = useState(0);
  const [sellBorrowingAmount, setSellBorrowingAmount] = useState(0);

  useEffect(() => {
    setAmount("");
    setSellAmount("");
    setBuyBorrowing(0);
    setSellBorrowing(0);
    setBuyEstFee(0);
    setSellEstFee(0);
    setBuyBorrowingAmount(0);
    setSellBorrowingAmount(0);
    // setWarning("");
    // setDisableButon(true);
    // setSellDisableButon(true);
  }, [activeTab, activeIndexAction, slug, tradingType, crossMarginLeverage, isolatedMarginLeverage, crossMarginSellLeverage, isolatedMarginSellLeverage]);

  //validations
  var reg = new RegExp(/^\d+(\.\d{0,8})?$/);

  useEffect(() => {
    setWarning("");
    if (tradingType === "cross") {
      if (activeTab?.toLowerCase() === "cross") {
        if (parseFloat(amount) <= parseFloat(getDigitsAfterDecimal(exchangeData?.currencyBalanceCross, currentMarketDetails[0]?.priceDecimalPrecision))) {
          setWarning("You can not place a margin order without borrowing amount.");
          setDisableButon(true);
        }
        else if (leverageState?.currencyBalanceCrossMaxLeverage === null && leverageState?.currencyBalanceCrossMaxLeverage?.length === 0) {
          setWarning("You can not place a margin order without borrowing amount.");
          setDisableButon(true);
        }
        else if (amount === "" || parseFloat(amount) <= 0) {
          setWarning("");
          setDisableButon(true);
        }
        else if (reg.test(parseFloat(amount)) === false) {
          setWarning('Only numeric character allowed');
          setDisableButon(true)
        }
        else if (crossMarginLeverage !== "") {
          if (parseFloat(amount) > parseFloat(getDigitsAfterDecimal((exchangeData?.currencyBalanceCross * crossMarginLeverage), currentMarketDetails[0]?.priceDecimalPrecision))) {
            setWarning('Insufficiant Balance')
            setDisableButon(true)
          }
          else {
            setDisableButon(false);
          }
        }
        else if (crossMarginLeverage == "") {
          if (parseFloat(amount) > parseFloat(getDigitsAfterDecimal((exchangeData?.currencyBalanceCross * leverageState?.currencyBalanceCrossMaxLeverage[leverageState?.currencyBalanceCrossMaxLeverage?.length - 1]), currentMarketDetails[0]?.priceDecimalPrecision))) {
            setWarning('Insufficiant Balance')
            setDisableButon(true)
          }
          else {
            setDisableButon(false);
          }
        }
        else {
          setWarning('');
          setDisableButon(false)
        }
        if (parseFloat(amount) > parseFloat(exchangeData?.currencyBalanceCross)) {
          // setBuyBorrowing(getDigitsAfterDecimal((parseFloat(amount) - parseFloat(exchangeData?.currencyBalanceCross)), currentMarketDetails[0]?.priceDecimalPrecision));
          setBuyBorrowing(parseFloat(buyBorrowingAmount) - parseFloat(exchangeData?.currencyBalanceCross));
        }
        else {
          setBuyBorrowing(0);
        }
      }
      else {
        if (parseFloat(amount) <= parseFloat(getDigitsAfterDecimal(exchangeData?.currencyBalanceIsolated, currentMarketDetails[0]?.priceDecimalPrecision))) {
          setWarning("You can not place a margin order without borrowing amount.");
          setDisableButon(true);
        }
        else if (leverageState?.currencyBalanceIsolatedMaxLeverage === null && leverageState?.currencyBalanceIsolatedMaxLeverage?.length === 0) {
          setDisableButon(true);
          setWarning("You can not place a margin order without borrowing amount.");
        }
        else if (amount === "" || parseFloat(amount) <= 0) {
          setWarning("");
          setDisableButon(true);
        }
        else if (reg.test(parseFloat(amount)) === false) {
          setWarning('Only numeric character allowed');
          setDisableButon(true)
        }
        else if (isolatedMarginLeverage) {
          if (parseFloat(amount) > parseFloat(getDigitsAfterDecimal((exchangeData?.currencyBalanceIsolated * isolatedMarginLeverage), currentMarketDetails[0]?.priceDecimalPrecision))) {
            setWarning('Insufficiant Balance')
            setDisableButon(true)
          }
          else {
            setDisableButon(false);
          }
        }
        else if (isolatedMarginLeverage === "") {
          if (parseFloat(amount) > parseFloat(getDigitsAfterDecimal((exchangeData?.currencyBalanceIsolated * leverageState?.currencyBalanceIsolatedMaxLeverage[leverageState?.currencyBalanceIsolatedMaxLeverage?.length - 1]), currentMarketDetails[0]?.priceDecimalPrecision))) {
            setWarning('Insufficiant Balance')
            setDisableButon(true)
          }
          else {
            setDisableButon(false);
          }
        }
        else {
          setWarning('');
          setDisableButon(false)
        }
        if (parseFloat(amount) > parseFloat(exchangeData?.currencyBalanceIsolated)) {
          // setBuyBorrowing(getDigitsAfterDecimal((parseFloat(amount) - parseFloat(exchangeData?.currencyBalanceIsolated)), currentMarketDetails[0]?.priceDecimalPrecision));
          setBuyBorrowing(parseFloat(buyBorrowingAmount) - parseFloat(exchangeData?.currencyBalanceIsolated));
        }
        else {
          setBuyBorrowing(0);
        }
      }
    }
    else if (amount != "" && amount > 0) {
      if (reg.test(amount) === false) {
        setWarning('Only numeric character allowed');
        setDisableButon(true)
      }
      else if (parseFloat(amount) > parseFloat(exchangeData?.currencyBalance)) {
        setWarning('Insufficiant Balance')
        setDisableButon(true)
      }
      else if (amount === 0) {
        setWarning('')
        setDisableButon(true)
      }
      else {
        setWarning('');
        setDisableButon(false)
      }
    }
    else {
      setWarning('');
      setDisableButon(true)
    }
  }, [amount, buyBorrowingAmount])

  useEffect(() => {
    setSellWarning("");
    if (tradingType === "cross") {
      if (activeTab?.toLowerCase() === "cross") {
        if (parseFloat(sellAmount) <= parseFloat(getDigitsAfterDecimal(exchangeData?.coinBalanceCross, currentMarketDetails[0]?.amountDecimalPrecision))) {
          setSellWarning("You can not place a margin order without borrowing amount.");
          setDisableButon(true);
        }
        else if (leverageState?.coinBalanceCrossMaxLeverage === null && leverageState?.coinBalanceCrossMaxLeverage?.length === 0) {
          setSellWarning(true);
          setSellWarning("You can not place a margin order without borrowing amount.");
        }
        else if (sellAmount === "" || parseFloat(sellAmount) <= 0) {
          setSellWarning("");
          setSellDisableButon(true);
        }
        else if (reg.test(sellAmount) === false) {
          setSellWarning('Only numeric character allowed');
          setSellDisableButon(true)
        }
        else if (crossMarginSellLeverage !== "") {
          if (parseFloat(sellAmount) > parseFloat(getDigitsAfterDecimal((exchangeData?.coinBalanceCross * crossMarginSellLeverage), currentMarketDetails[0]?.amountDecimalPrecision))) {
            setSellWarning('Insufficiant Balance')
            setSellDisableButon(true)
          }
          else {
            setSellDisableButon(false);
          }
        }
        else if (crossMarginSellLeverage == "") {
          if (parseFloat(sellAmount) > parseFloat(getDigitsAfterDecimal((exchangeData?.coinBalanceCross * leverageState?.coinBalanceCrossMaxLeverage), currentMarketDetails[0]?.amountDecimalPrecision))) {
            setSellWarning('Insufficiant Balance')
            setSellDisableButon(true)
          }
          else {
            setSellDisableButon(false);
          }
        }
        else {
          setSellWarning('');
          setSellDisableButon(false)
        }
        if (parseFloat(sellAmount) > parseFloat(exchangeData?.coinBalanceCross)) {
          setSellBorrowing(parseFloat(sellBorrowingAmount) - parseFloat(exchangeData?.coinBalanceCross));
        }
        else {
          setSellBorrowing(0);
        }
      }
      else {
        if (parseFloat(sellAmount) <= parseFloat(getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated, currentMarketDetails[0]?.amountDecimalPrecision))) {
          setSellWarning("You can not place a margin order without borrowing amount.");
          setDisableButon(true);
        }
        else if (leverageState?.coinBalanceIsolatedMaxLeverage === null && leverageState?.coinBalanceIsolatedMaxLeverage?.length === 0) {
          setSellWarning(true);
          setSellWarning("You can not place a margin order without borrowing amount.");
        }
        else if (sellAmount === "" || parseFloat(amount) <= 0) {
          setSellWarning("");
          setSellDisableButon(true);
        }
        else if (reg.test(sellAmount) === false) {
          setSellWarning('Only numeric character allowed');
          setSellDisableButon(true)
        }
        // else if (parseFloat(sellAmount) > parseFloat(exchangeData?.coinBalanceIsolated * currentMarketDetails[0]?.maxLeverageTrading)) {
        //   setSellWarning('Insufficiant Balance')
        //   setSellDisableButon(true)
        // }
        else if (isolatedMarginSellLeverage !== "") {
          if (parseFloat(sellAmount) > parseFloat(getDigitsAfterDecimal((exchangeData?.coinBalanceIsolated * isolatedMarginSellLeverage), currentMarketDetails[0]?.amountDecimalPrecision))) {
            setSellWarning('Insufficiant Balance')
            setSellDisableButon(true)
          }
          else {
            setSellDisableButon(false);
          }
        }
        else if (isolatedMarginSellLeverage == "") {
          if (parseFloat(sellAmount) > parseFloat(getDigitsAfterDecimal((exchangeData?.coinBalanceIsolated * leverage?.coinBalanceIsolatedMaxLeverage[leverageState?.coinBalanceIsolatedMaxLeverage?.length - 1]), currentMarketDetails[0]?.amountDecimalPrecision))) {
            setSellWarning('Insufficiant Balance')
            setSellDisableButon(true)
          }
          else {
            setSellDisableButon(false);
          }
        }
        else {
          setSellWarning('');
          setSellDisableButon(false)
        }
        if (parseFloat(sellAmount) > parseFloat(exchangeData?.coinBalanceIsolated)) {
          setSellBorrowing((parseFloat(sellBorrowingAmount) - parseFloat(exchangeData?.coinBalanceIsolated)));
        }
        else {
          setSellBorrowing(0);
        }
      }
    }
    else if (sellAmount != "" && sellAmount > 0) {
      if (reg.test(sellAmount) === false) {
        setSellWarning('Only numeric character allowed');
        setSellDisableButon(true)
      }
      else if (parseFloat(sellAmount) > parseFloat(exchangeData?.coinBalance)) {
        setSellWarning('Insufficiant Balance')
        setSellDisableButon(true)
      }
      else if (sellAmount === 0) {
        setSellWarning('')
        setSellDisableButon(true)
      }
      else {
        setSellWarning('');
        setSellDisableButon(false)
      }
    }
    else {
      setSellWarning('');
      setSellDisableButon(true)
    }
  }, [sellAmount, sellBorrowingAmount])

  useEffect(() => {
    if (response?.status === 200) {
      addNotification({
        title: 'Success',
        message: 'Order Placed',
        type: 'success'
      })
      setSellLoading(false)
      setBuyLoading(false)
      orderResponse(response)
      dispatch(userStatusCreator())
      dispatch(newOrderEmpty())
      createOrdersData(slug);
      socket.emit('userIdMargin', { userId: userStatus?.id });
    }
  }, [response])

  useEffect(() => {
    if (orderError?.msg) {
      addNotification({
        title: 'Error',
        message: orderError.msg,
        type: 'danger'
      })
      socket.emit('userIdMargin', { userId: userStatus?.id });
      dispatch(newOrderEmpty())
    }
  }, [orderError])

  //buy calculations
  useEffect(() => {
    setBuyTotal('');
    // if (limit && amount > 0) {
    if (amount > 0) {
      // fiat calculations
      let fiat_amount = (1 - (takerFee / 100)) * parseFloat(amount);
      let buyEstFee = parseFloat(amount) - parseFloat(fiat_amount);
      setBuyEstFee(buyEstFee);
      let fiat_spread_calculation = (1 - (spread / 100)) * parseFloat(fiat_amount);
      let fiat_spread = parseFloat(fiat_amount) - parseFloat(fiat_spread_calculation);
      // crypto calculations
      let crypto_spread = parseFloat(fiat_spread) / parseFloat(buyPriceLimit);
      let app_crypto_total = parseFloat(fiat_amount) / parseFloat(buyPriceLimit);
      let customer_crypto_total = (parseFloat(app_crypto_total) - parseFloat(crypto_spread));
      setBuyTotal(parseFloat(customer_crypto_total).toFixed(8));
    }
  }, [amount, buyPriceLimit]);

  //sell calculation
  useEffect(() => {

    setSellTotal('');
    // if (limit && sellAmount > 0) {
    if (sellAmount > 0) {
      // fiat calculations
      let fiat_spread_calculation = (1 - (spread / 100)) * parseFloat(sellPriceLimit);
      let fiat_spread = parseFloat(sellPriceLimit) - parseFloat(fiat_spread_calculation);
      // crypto calculations
      let crypto_amount = (1 - (makerFee / 100)) * parseFloat(sellAmount);
      let sellEstFee = parseFloat(sellAmount) - parseFloat(crypto_amount);
      setSellEstFee(sellEstFee);
      let app_fiat_total = parseFloat(sellPriceLimit) * parseFloat(crypto_amount);
      let customer_fiat_total = parseFloat(app_fiat_total) - parseFloat(fiat_spread);
      setSellTotal(parseFloat(customer_fiat_total).toFixed(2));
    }
  }, [sellAmount, sellPriceLimit]);

  //buy data goes here
  const buyHandler = () => {
    if (userStatus.kyc_verification && userStatus.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      setWarning('Please verify your KYC first')
      setDisableButon(true)
    } else {
      if (limit) {
        setBuyLoading(true)
        dispatch(newOrderCreator(marketId, process.env.REACT_APP_BUY_LIMIT_ORDER, amount, null, buyPriceLimit))
      } else {
        dispatch(newOrderCreator(marketId, process.env.REACT_APP_BUY_MARKET_ORDER, amount))
      }
      setAmount(''); setBuyPriceLimit(currentMarketPrice);
      setBuyLoading(false);
    }
  }

  const buyMarginHandler = () => {
    if (userStatus.kyc_verification && userStatus.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      setWarning('Please verify your KYC first');
      setDisableButon(true);
    }
    else {
      setBuyLoading(true);
      if (limit) {
        newMarginOrderHandler(marketId, process.env.REACT_APP_BUY_LIMIT_ORDER, amount, null, buyPriceLimit, activeTab?.toLowerCase() === "cross" ? parseInt(process.env.REACT_APP_CROSS_MARGIN) : parseInt(process.env.REACT_APP_ISOLATED_MARGIN), buyBorrowing);
      }
      else {
        newMarginOrderHandler(marketId, process.env.REACT_APP_BUY_MARKET_ORDER, amount, null, null, activeTab?.toLowerCase() === "cross" ? parseInt(process.env.REACT_APP_CROSS_MARGIN) : parseInt(process.env.REACT_APP_ISOLATED_MARGIN), buyBorrowing);
      }
      setAmount('');
      setBuyPriceLimit(currentMarketPrice);
      setBuyLoading(false);
    };
  };

  //sell data 
  const sellHandler = () => {
    setSellLoading(true)
    if (limit) {
      dispatch(newOrderCreator(marketId, process.env.REACT_APP_SELL_LIMIT_ORDER, null, sellAmount, sellPriceLimit))
    } else {
      dispatch(newOrderCreator(marketId, process.env.REACT_APP_SELL_MARKET_ORDER, null, sellAmount))
    }
    setSellAmount('');
    setSellPriceLimit(currentMarketPrice);
    setSellLoading(false);
  }

  const sellMarginHandler = () => {
    if (userStatus.kyc_verification && userStatus.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETE)) {
      setWarning('Please verify your KYC first');
      setDisableButon(true);
    }
    else {
      setSellLoading(true);
      if (limit) {
        newMarginOrderHandler(marketId, process.env.REACT_APP_SELL_LIMIT_ORDER, null, sellAmount, sellPriceLimit, activeTab?.toLowerCase() === "cross" ? parseInt(process.env.REACT_APP_CROSS_MARGIN) : parseInt(process.env.REACT_APP_ISOLATED_MARGIN), sellBorrowing);
      }
      else {
        newMarginOrderHandler(marketId, process.env.REACT_APP_SELL_MARKET_ORDER, null, sellAmount, null, activeTab?.toLowerCase() === "cross" ? parseInt(process.env.REACT_APP_CROSS_MARGIN) : parseInt(process.env.REACT_APP_ISOLATED_MARGIN), sellBorrowing);
      };
    };
    setSellAmount('');
    setSellPriceLimit(currentMarketPrice);
    setSellLoading(false);
  };

  const newMarginOrderHandler = async (market_id, order_type, amount = null, sellAmount = null, limiPrice = null, margin_type, borrow_amount) => {
    let data = {
      marketId: market_id,
      orderType: parseInt(order_type),
      fiat_price: limiPrice,
      fiat_spend: amount,
      crypto_spend: parseFloat(sellAmount),
      signature: localStorage.getItem("signature"),
      margin_type: margin_type,
      borrow_amount: borrow_amount,
    };
    // console.log("data", data);
    try {
      const newMarginOrderPayload = await requestHandler("newMarginOrder", "post", data, "jwt_token");
      // console.log("newMarginOrderPayload", newMarginOrderPayload);
      if (newMarginOrderPayload.status === 200) {
        addNotification({
          title: "Success",
          message: newMarginOrderPayload?.data?.message[0]?.msg,
          type: "success",
        });
        orderResponse(newMarginOrderPayload);
        setBuyEstFee(0);
        setSellEstFee(0);
      }
      dispatch(userStatusCreator());
      setOrderFlag(!orderFlag);
    }
    catch (e) {
      // console.log("error", e);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    };
  };

  useEffect(() => {
    setBuyPriceLimit(currentMarketPrice);
    setSellPriceLimit(currentMarketPrice);
  }, [currentMarketPrice]);

  return (
    <>
      {tradingType === "cross" ?
        activeTab?.toLowerCase() === "cross" ?
          <div className={styles.container}>
            <div className={styles.head}>
              <div className={styles.nav}>
                {navigation.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
            </div>
            {orderType && orderType == "buy" ?
              <div className={styles.content}>
                <div className={styles.info}>
                  <span className={styles.label}>Avbl</span>
                  <span className={styles.value}
                    onClick={(e) => {
                      parseFloat(exchangeData?.currencyBalanceCross) > 0 ?
                        setBuyBorrowingAmount(exchangeData?.currencyBalanceCross)
                        :
                        setBuyBorrowingAmount("")
                      parseFloat(exchangeData?.currencyBalanceCross) > 0 ?
                        setAmount(getDigitsAfterDecimal((exchangeData?.currencyBalanceCross), currentMarketDetails[0]?.priceDecimalPrecision))
                        :
                        setAmount("")
                    }}>
                    {`${getDigitsAfterDecimal((exchangeData?.currencyBalanceCross), 3)} ${currency}`}
                  </span>
                </div>
                <div className={styles.info}
                  onClick={(e) => {
                    if (leverageState?.currencyBalanceCrossMaxLeverage !== null) {
                      if (crossMarginLeverage === undefined || crossMarginLeverage === "") {
                        setBuyBorrowingAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceCross * leverageState?.currencyBalanceCrossMaxLeverage[leverageState?.currencyBalanceCrossMaxLeverage?.length - 1], currentMarketDetails[0]?.priceDecimalPrecision))
                      }
                      else {
                        setBuyBorrowingAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceCross * crossMarginLeverage, currentMarketDetails[0]?.priceDecimalPrecision));
                      }
                    }
                    else {
                      setBuyBorrowingAmount("0.00")
                    }

                    if (leverageState?.currencyBalanceCrossMaxLeverage !== null) {
                      if (crossMarginLeverage === undefined || crossMarginLeverage === "") {
                        setAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceCross * leverageState?.currencyBalanceCrossMaxLeverage[leverageState?.currencyBalanceCrossMaxLeverage?.length - 1], currentMarketDetails[0]?.priceDecimalPrecision))
                      }
                      else {
                        setAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceCross * crossMarginLeverage, currentMarketDetails[0]?.priceDecimalPrecision));
                      }
                    }
                    else {
                      setAmount("0.00")
                    }
                  }}>
                  <span className={styles.label}>Max</span>
                  <span className={styles.value}>
                    {crossMarginLeverage === undefined || crossMarginLeverage === "" ?
                      leverageState?.currencyBalanceCrossMaxLeverage !== null ?
                        getDigitsAfterDecimal((exchangeData?.currencyBalanceCross * leverageState?.currencyBalanceCrossMaxLeverage[leverageState?.currencyBalanceCrossMaxLeverage?.length - 1]), 3)
                        :
                        "0.00"
                      :
                      <span>{getDigitsAfterDecimal(exchangeData?.currencyBalanceCross * crossMarginLeverage, 3)}</span>
                    } {currency}
                  </span>
                </div>
              </div>
              :
              <div className={styles.content}>
                <div className={styles.info}>
                  <span className={styles.label}>Avbl</span>
                  <span className={styles.value}
                    onClick={() => {
                      parseFloat(exchangeData?.coinBalanceCross) > 0 ?
                        setSellBorrowingAmount(exchangeData?.coinBalanceCross)
                        :
                        setSellBorrowingAmount("")

                      parseFloat(exchangeData?.coinBalanceCross) > 0 ?
                        setSellAmount(getDigitsAfterDecimal((exchangeData?.coinBalanceCross), currentMarketDetails[0]?.amountDecimalPrecision))
                        :
                        setSellAmount("")
                    }}>
                    {`${getDigitsAfterDecimal((exchangeData?.coinBalanceCross), currentMarketDetails[0]?.amountDecimalPrecision)} ${coin}`}
                  </span>
                </div>
                <div className={styles.info}>
                  <span className={styles.label}>Max</span>
                  <span className={styles.value}
                    onClick={(e) => {
                      if (leverageState?.coinBalanceCrossMaxLeverage !== null) {
                        if (crossMarginSellLeverage === undefined || crossMarginSellLeverage === "") {
                          setSellBorrowingAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceCross * leverageState?.coinBalanceCrossMaxLeverage[leverageState?.coinBalanceCrossMaxLeverage?.length - 1], currentMarketDetails[0]?.amountDecimalPrecision))
                        }
                        else {
                          setSellBorrowingAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceCross * crossMarginSellLeverage, currentMarketDetails[0]?.amountDecimalPrecision));
                        }
                      }
                      else {
                        setSellBorrowingAmount("0.00")
                      }

                      if (leverageState?.coinBalanceCrossMaxLeverage !== null) {
                        if (crossMarginSellLeverage === undefined || crossMarginSellLeverage === "") {
                          setSellAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceCross * leverageState?.coinBalanceCrossMaxLeverage[leverageState?.coinBalanceCrossMaxLeverage?.length - 1], currentMarketDetails[0]?.amountDecimalPrecision))
                        }
                        else {
                          setSellAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceCross * crossMarginSellLeverage, currentMarketDetails[0]?.amountDecimalPrecision));
                        }
                      }
                      else {
                        setSellAmount("0.00")
                      }
                    }}>
                    {crossMarginSellLeverage === undefined || crossMarginSellLeverage === "" ?
                      leverageState?.coinBalanceCrossMaxLeverage !== null ?
                        getDigitsAfterDecimal((exchangeData?.coinBalanceCross * leverageState?.coinBalanceCrossMaxLeverage[leverageState?.coinBalanceCrossMaxLeverage?.length - 1]), currentMarketDetails[0]?.amountDecimalPrecision)
                        :
                        "0.00"
                      :
                      <span>{getDigitsAfterDecimal(exchangeData?.coinBalanceCross * crossMarginSellLeverage, currentMarketDetails[0]?.amountDecimalPrecision)}</span>
                    } {coin}
                  </span>
                </div>
              </div>
            }
          </div >
          :
          <div className={styles.container}>
            <div className={styles.head}>
              <div className={styles.nav}>
                {navigation.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
            </div>
            {orderType && orderType == "buy" ?
              <div className={styles.content}>
                <div className={styles.info}>
                  <span className={styles.label}>Avbl</span>
                  <span className={styles.value}
                    onClick={(e) => {
                      parseFloat(exchangeData?.currencyBalanceIsolated) > 0 ?
                        setBuyBorrowingAmount(exchangeData?.currencyBalanceIsolated)
                        :
                        setBuyBorrowingAmount("")
                      parseFloat(exchangeData?.currencyBalanceIsolated) > 0 ?
                        setAmount(getDigitsAfterDecimal((exchangeData?.currencyBalanceIsolated), currentMarketDetails[0]?.priceDecimalPrecision))
                        :
                        setAmount("")
                    }}>
                    {`${getDigitsAfterDecimal((exchangeData?.currencyBalanceIsolated), 3)} ${currency}`}
                  </span>
                </div>
                <div className={styles.info}
                  onClick={(e) => {
                    if (leverageState?.currencyBalanceIsolatedMaxLeverage !== null) {
                      if (isolatedMarginLeverage === undefined || isolatedMarginLeverage === "") {
                        setBuyBorrowingAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceIsolated * leverageState?.currencyBalanceIsolatedMaxLeverage[leverageState?.currencyBalanceIsolatedMaxLeverage?.length - 1], currentMarketDetails[0]?.priceDecimalPrecision))
                      }
                      else {
                        setBuyBorrowingAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceIsolated * isolatedMarginLeverage, currentMarketDetails[0]?.priceDecimalPrecision));
                      }
                    }
                    else {
                      setBuyBorrowingAmount("0.00")
                    }

                    if (leverageState?.currencyBalanceIsolatedMaxLeverage !== null) {
                      if (isolatedMarginLeverage === undefined || isolatedMarginLeverage === "") {
                        setAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceIsolated * leverageState?.currencyBalanceIsolatedMaxLeverage[leverageState?.currencyBalanceIsolatedMaxLeverage?.length - 1], currentMarketDetails[0]?.priceDecimalPrecision))
                      }
                      else {
                        setAmount(getDigitsAfterDecimal(exchangeData?.currencyBalanceIsolated * isolatedMarginLeverage, currentMarketDetails[0]?.priceDecimalPrecision));
                      }
                    }
                    else {
                      setAmount("0.00")
                    }
                  }}>
                  <span className={styles.label}>Max</span>
                  <span className={styles.value}>
                    {isolatedMarginLeverage === undefined || isolatedMarginLeverage === "" ?
                      leverageState?.currencyBalanceIsolatedMaxLeverage !== null ?
                        getDigitsAfterDecimal((exchangeData?.currencyBalanceIsolated * leverageState?.currencyBalanceIsolatedMaxLeverage[leverageState?.currencyBalanceIsolatedMaxLeverage?.length - 1]), 3)
                        :
                        "0.00"
                      :
                      <span>{getDigitsAfterDecimal(exchangeData?.currencyBalanceIsolated * isolatedMarginLeverage, 3)}</span>
                    } {currency}
                  </span>
                </div>
              </div>
              :
              <div className={styles.content}>
                <div className={styles.info}>
                  <span className={styles.label}>Avbl</span>
                  <span className={styles.value}
                    onClick={() => {
                      parseFloat(exchangeData?.coinBalanceIsolated) > 0 ?
                        setSellBorrowingAmount(exchangeData?.coinBalanceIsolated)
                        :
                        setSellBorrowingAmount("")
                      parseFloat(exchangeData?.coinBalanceIsolated) > 0 ?
                        setSellBorrowingAmount(getDigitsAfterDecimal((exchangeData?.coinBalanceIsolated), currentMarketDetails[0]?.amountDecimalPrecision))
                        :
                        setSellBorrowingAmount("")
                    }}>
                    {`${getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated, currentMarketDetails[0]?.amountDecimalPrecision)} ${coin}`}
                  </span>
                </div>
                <div className={styles.info}>
                  <span className={styles.label}>Max</span>
                  <span className={styles.value}
                    onClick={(e) => {
                      if (leverageState?.coinBalanceIsolatedMaxLeverage !== null) {
                        if (isolatedMarginSellLeverage === undefined || isolatedMarginSellLeverage === "") {
                          setSellBorrowingAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated * leverageState?.coinBalanceIsolatedMaxLeverage[leverageState?.coinBalanceIsolatedMaxLeverage?.length - 1], currentMarketDetails[0]?.amountDecimalPrecision))
                        }
                        else {
                          setSellBorrowingAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated * isolatedMarginSellLeverage, currentMarketDetails[0]?.amountDecimalPrecision));
                        }
                      }
                      else {
                        setSellBorrowingAmount("0.00")
                      }

                      if (leverageState?.coinBalanceIsolatedMaxLeverage !== null) {
                        if (isolatedMarginSellLeverage === undefined || isolatedMarginSellLeverage === "") {
                          setSellAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated * leverageState?.coinBalanceIsolatedMaxLeverage[leverageState?.coinBalanceIsolatedMaxLeverage?.length - 1], currentMarketDetails[0]?.amountDecimalPrecision))
                        }
                        else {
                          setSellAmount(getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated * isolatedMarginSellLeverage, currentMarketDetails[0]?.amountDecimalPrecision));
                        }
                      }
                      else {
                        setSellAmount("0.00")
                      }
                    }}>
                    {isolatedMarginSellLeverage === undefined || isolatedMarginSellLeverage === "" ?
                      leverageState?.coinBalanceIsolatedMaxLeverage !== null ?
                        getDigitsAfterDecimal((exchangeData?.coinBalanceIsolated * leverageState?.coinBalanceIsolatedMaxLeverage[leverageState?.coinBalanceIsolatedMaxLeverage?.length - 1]), currentMarketDetails[0]?.amountDecimalPrecision)
                        :
                        "0.00"
                      :
                      <span>{getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated * isolatedMarginSellLeverage, currentMarketDetails[0]?.amountDecimalPrecision)}</span>
                    } {coin}
                  </span>
                </div>
              </div>
            }
          </div>
        :
        ""
      }

      {/* {
        tradingType?.toLowerCase() === "cross" ?
          activeTab?.toLowerCase() === "cross" ?
            orderType && orderType === "buy" ?
              <div className={styles.container}>
                <div className={styles.head}>
                  <div className={styles.nav}>
                    {navigation.map((x, index) => (
                      <button
                        className={cn(styles.link, {
                          [styles.active]: index === activeIndex,
                        })}
                        onClick={() => setActiveIndex(index)}
                        key={index}
                      >
                        {x}
                      </button>
                    ))}
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.info}>
                    <span className={styles.label}>Avbl</span>
                    <span className={styles.value}
                      onClick={(e) => {
                        setAmount(exchangeData?.currencyBalanceCross);
                      }}>
                      {`${getDigitsAfterDecimal(exchangeData?.currencyBalanceCross, currentMarketDetails[0]?.priceDecimalPrecision)} ${currency}`}
                    </span>
                  </div>
                  <div className={styles.info}
                    onClick={(e) => {
                      setAmount(exchangeData?.currencyBalanceCross * currentMarketDetails[0]?.maxLeverageTrading);
                    }}>
                    <span className={styles.label}>Max</span>
                    <span className={styles.value}>{currentMarketDetails[0]?.isMarginTrading === parseInt(process.env.REACT_APP_MARGIN_TRADING_ENABLE) ? (exchangeData?.currencyBalanceCross * currentMarketDetails[0]?.maxLeverageTrading) : exchangeData?.currencyBalanceCross} {currency}</span>
                  </div>
                </div>
                :
                ""
                :
                ""
                :
                ""
      } */}

      {
        tradingType?.toLowerCase() === "cross" ?
          activeTab?.toLowerCase() === "cross" ?
            orderType && orderType === "buy" ?
              <div className={styles.head}>
                <div className={styles.title}>{title}</div>
                <div className={styles.counter}
                  onClick={() => {
                    setAmount(getDigitsAfterDecimal((exchangeData?.currencyBalanceCross), currentMarketDetails[0]?.priceDecimalPrecision));
                  }}>
                  <Icon name="wallet" size="16" />
                  {getDigitsAfterDecimal(exchangeData?.currencyBalanceCross, currentMarketDetails[0]?.priceDecimalPrecision)} {currency}
                </div>
              </div>
              :
              <div className={styles.head}>
                <div className={styles.title}>{title}</div>
                <div className={styles.counter}
                  onClick={() => {
                    setSellAmount(getDigitsAfterDecimal((exchangeData?.coinBalanceCross), currentMarketDetails[0]?.amountDecimalPrecision));
                  }}>
                  <Icon name="wallet" size="16" />
                  {getDigitsAfterDecimal(exchangeData?.coinBalanceCross, currentMarketDetails[0]?.amountDecimalPrecision)} {coin}
                </div>
              </div>
            :
            activeTab?.toLowerCase() === "isolated" ?
              orderType && orderType === "buy" ?
                <div className={styles.head}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.counter}
                    onClick={() => {
                      setAmount(getDigitsAfterDecimal((exchangeData?.currencyBalanceIsolated), currentMarketDetails[0]?.priceDecimalPrecision));
                    }}>
                    <Icon name="wallet" size="16" />
                    {getDigitsAfterDecimal(exchangeData?.currencyBalanceIsolated, currentMarketDetails[0]?.priceDecimalPrecision)} {currency}
                  </div>
                </div>
                :
                <div className={styles.head}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.counter}
                    onClick={() => {
                      setSellAmount(getDigitsAfterDecimal((exchangeData?.coinBalanceIsolated), currentMarketDetails[0]?.amountDecimalPrecision));
                    }}>
                    <Icon name="wallet" size="16" />
                    {getDigitsAfterDecimal(exchangeData?.coinBalanceIsolated, currentMarketDetails[0]?.amountDecimalPrecision)} {coin}
                  </div>
                </div>
              :
              ""
          :
          orderType && orderType === "buy" ?
            <div className={styles.head}>
              <div className={styles.title}>{title}</div>
              <div className={styles.counter}
                onClick={() => {
                  setAmount(getDigitsAfterDecimal((exchangeData?.currencyBalance), currentMarketDetails[0]?.priceDecimalPrecision));
                }}>
                <Icon name="wallet" size="16" />
                {getDigitsAfterDecimal(exchangeData?.currencyBalance, currentMarketDetails[0]?.priceDecimalPrecision)} {currency}
              </div>
            </div>
            :
            <div className={styles.head}>
              <div className={styles.title}>{title}</div>
              <div className={styles.counter}
                onClick={() => {
                  setSellAmount(getDigitsAfterDecimal((exchangeData?.coinBalance), currentMarketDetails[0]?.amountDecimalPrecision));
                }}>
                <Icon name="wallet" size="16" />
                {getDigitsAfterDecimal(exchangeData?.coinBalance, currentMarketDetails[0]?.amountDecimalPrecision)} {coin}
              </div>
            </div>
      }

      {/*  */}
      {/* <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <div className={styles.counter}
          onClick={() => {
            setAmount(parseFloat(content));
            setSellAmount(parseFloat(content))
          }}>
          <Icon name="wallet" size="16" />
          {content}
        </div>
      </div> */}
      {/*  */}

      {
        price && (
          <label className={styles.field}>
            <div className={styles.label}>Price</div>
            <input className={styles.input} type="text" name="price" value="MarketPrice" required />
            <div className={styles.currency}>{currency}</div>
          </label>
        )
      }
      {
        stop && (
          <label className={styles.field}>
            <div className={styles.label}>Stop</div>
            <input className={styles.input} type="text" name="stop" required />
            <div className={styles.currency}>BTC</div>
          </label>
        )
      }
      {
        limit && (
          <label className={styles.field}>
            <div className={styles.label}>Limit Price</div>
            {orderType && orderType == "buy" && (
              <input className={styles.input}
                type="text"
                name="limit"
                value={buyPriceLimit}
                required
                onChange={(e) => setBuyPriceLimit(e.target.value)}
              />
            )}
            {orderType && orderType == "sell" && (
              <input className={styles.input}
                type="text"
                name="limit"
                value={sellPriceLimit}
                required
                onChange={(e) => setSellPriceLimit(e.target.value)}
              />
            )}
            <div className={styles.currency}>{currency}</div>
          </label>
        )
      }
      {
        orderType && orderType == "buy" && (
          <>
            <label className={styles.field}>
              <div className={styles.label}>Amount</div>
              <input className={styles.input} type="text" name="amount" required value={amount}
                onClick={() => {
                  setMarketOrderType("buy");
                }}
                onChange={(e) => {
                  setBuyBorrowingAmount(e.target.value);
                  setAmount(e.target.value);
                }}
              />
              <div className={styles.currency}>{currency}</div>
            </label><span style={{ color: 'red' }}>{warning}</span>
          </>
        )
      }
      {
        orderType && orderType == "sell" && (
          <>
            <label className={styles.field}>
              <div className={styles.label}>Amount</div>
              <input className={styles.input}
                type="text"
                name="amount"
                required
                value={sellAmount}
                onClick={() => {
                  setMarketOrderType("sell");
                }}
                onChange={(e) => {
                  setSellBorrowingAmount(e.target.value);
                  setSellAmount(e.target.value);
                }} />
              <div className={styles.currency}>{coin}</div>
            </label>
            <span style={{ color: 'red' }}>{sellWarning}</span>
          </>
        )
      }
      {
        limit && (
          <label className={styles.field}>
            <div className={styles.label}>Total</div>
            {
              orderType && orderType == "buy" && (
                <>
                  <input className={styles.input}
                    type="text"
                    name="total"
                    required
                    value={buyTotal}
                    disabled={true}
                  />
                  <div className={styles.currency}>{coin}</div>
                </>
              )
            }
            {
              orderType && orderType == "sell" && (
                <>
                  <input
                    className={styles.input}
                    type="text"
                    name="total"
                    required
                    value={sellTotal}
                    disabled={true}
                  />
                  <div className={styles.currency}>{currency}</div>
                </>
              )}
          </label>
        )
      }
      {
        !limit && (
          <label className={styles.hideClass}>
            <div className={styles.label}>Total</div>
            {
              orderType && orderType == "buy" && (
                <>
                  <input className={styles.input}
                    type="text"
                    name="total"
                    required
                    value={buyTotal}
                    disabled={true}
                    readOnly
                  />
                  <div className={styles.currency}>{coin}</div>
                </>
              )}
            {orderType && orderType == "sell" && (
              <>
                <input className={styles.input}
                  type="text"
                  name="total"
                  required
                  value={sellTotal}
                  disabled={true}
                  readOnly
                />
                <div className={styles.currency}>{currency}</div>
              </>
            )}
          </label>
        )
      }
      {
        orderType && orderType == "buy" && (
          <button className={cn(classButton, styles.button)}
            disabled={
              userStatus?.id === parseInt(process.env.REACT_APP_BOT_USER_ID) || loading
                ?
                true
                :
                disableButton
            }
            onClick={() => {
              if (tradingType?.toLowerCase() === "cross") {
                buyMarginHandler();
              }
              else {
                buyHandler();
              }
            }
            }
          >
            {buyLoading || loading ? "Please wait..." : buttonText}
          </button>
        )
      }
      {
        orderType && orderType == "sell" && (
          <button className={cn(classButton, styles.button)}
            disabled={userStatus?.id === parseInt(process.env.REACT_APP_BOT_USER_ID) || loading
              ?
              true
              :
              sellDisableButton
            }
            onClick={() => {
              if (tradingType?.toLowerCase() === "cross") {
                sellMarginHandler();
              }
              else {
                sellHandler()
              }
            }
            }
          >
            {sellLoading || loading ? "Please wait..." : buttonText} </button>
        )
      }
      {
        tradingType === "cross" ?
          orderType && orderType == "buy" ?
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.info}>
                  <span className={styles.label}>Est. Fee</span>
                  <span className={styles.value}>{getDigitsAfterDecimal(buyEstFee, currentMarketDetails[0]?.priceDecimalPrecision)} {currency}</span>
                </div>
                <div className={styles.info}>
                  <span className={styles.label}>Borrowing</span>
                  <span className={styles.value}>{getDigitsAfterDecimal(buyBorrowing, currentMarketDetails[0]?.priceDecimalPrecision)} {currency}</span>
                </div>
              </div>
            </div>
            :
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.info}>
                  <span className={styles.label}>Est. Fee</span>
                  <span className={styles.value}>{getDigitsAfterDecimal(sellEstFee, currentMarketDetails[0]?.amountDecimalPrecision)} {coin}</span>
                </div>
                <div className={styles.info}>
                  <span className={styles.label}>Borrowing</span>
                  <span className={styles.value}>{getDigitsAfterDecimal(sellBorrowing, currentMarketDetails[0]?.amountDecimalPrecision)} {coin}</span>
                </div>
              </div>
            </div>
          :
          ""
      }
    </>
  );
};

export default Action;
